.App {
    background-color: rgb(238, 238, 238);
}

/* Navbar */

.site-title {
    text-decoration: none;
    color: black;
}

.user-email {
    font-weight: bold;
    padding-right: 1vw;
}

.btn-login {
    margin-right: 1vw;
}

/* Login Page */

.login-page {
    height: 91vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-form {
    width: 30vw;
    height: 40vh;
    min-height: 225px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgb(248, 248, 248);
    -webkit-box-shadow: 0px 0px 36px 1px rgba(0, 0, 0, 0.8);
    box-shadow: 0px 0px 36px 1px rgba(0, 0, 0, 0.8);
    border-radius: 10px;
    color: black;
}

.login-form h3 {
    font-weight: bold;
}

.email-label {
    margin: 2vh 0 0;
    padding: 0;
}

.password-label {
    margin: 0;
    padding: 0;
}

.login-form button {
    margin-top: 3vh;
}

.email-input {
    width: 60%;
    height: 6vh;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    margin-bottom: -1px;
}

.password-input {
    width: 60%;
    height: 6vh;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin-bottom: 1vh;
}

/* Signup Page */

.signup-page {
    height: 91vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.signup-form {
    width: 30vw;
    height: 40vh;
    min-height: 225px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgb(248, 248, 248);
    -webkit-box-shadow: 0px 0px 36px 1px rgba(0, 0, 0, 0.8);
    box-shadow: 0px 0px 36px 1px rgba(0, 0, 0, 0.8);
    border-radius: 10px;
    color: black;
}

.signup-form h3 {
    font-weight: bold;
}

.signup-form button {
    margin-top: 3vh;
}

/* Home Page */

.home {
    padding-top: 2vh;
    height: 84vh;
}

.card-container {
    box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.273);
    border-radius: 10px;
    margin: 2vh 2vh;
    padding: 0.5rem;
    background-color: rgb(248, 248, 248);
}

.btn-details {
    min-width: 3vw;
    margin-right: 1vw;
}

.btn-edit {
    min-width: 3vw;
    margin-right: 0.8vw;
}

.btn-delete {
    min-width: 3vw;
}

.flex-container {
    display: flex;
    justify-content: space-evenly;
}

.board {
    display: flex;
    flex-direction: column;
    border: 2px solid #026bff;
    border-radius: 10px;
    min-height: 80vh;
    min-width: 19vw;
}

.board-header {
    text-align: center;
    padding-bottom: 0.5vh;
    padding-top: 2vh;
}

.btn-add-job {
    margin-left: 1.5vw;
    margin-top: 2vh;
}

/* Even if the board is empty, the entire container is still a droppable area. 
Without this rule, and empty board would shrink to nothing because its a flex item. */
.droppable {
    flex-grow: 1;
}

.confirm-delete-popup {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgb(248, 248, 248);
    -webkit-box-shadow: 0px 0px 36px 1px rgba(0, 0, 0, 0.8);
    box-shadow: 0px 0px 36px 1px rgba(0, 0, 0, 0.8);
    border-radius: 10px;
    padding: 2px 5px;
    width: 30vw;
    height: 20vh;
}

.confirm-delete-text {
    font-size: 1.3rem;
}

.btn-cancel-delete {
    margin-left: 2vw;
}

.card-form-popup {
    padding: 2px 5px;
    line-height: 20px;
    font-size: 18px;
    width: 40vw;
    background-color: rgb(248, 248, 248);
    -webkit-box-shadow: 0px 0px 36px 1px rgba(0, 0, 0, 0.8);
    box-shadow: 0px 0px 36px 1px rgba(0, 0, 0, 0.8);
    border-radius: 10px;
}

.card-form-popup form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.card-form-popup .header {
    padding-top: 1vh;
    text-align: center;
}

form label {
    padding: 1vh 0 1vh 0;
}

form input,
form textarea {
    width: 50%;
}

.card-form-popup button {
    margin: 2vh 0 2vh 0;
}

.job-details-popup {
    line-height: 1.8rem;
    font-size: 1.3rem;
}

.map-container {
    width: 100%;
    height: 400px;
    border: 1px solid #ccc;
}
